.container {
  max-width: 1000px;
  margin: auto;
  // padding: 20px;
  .tabs {
    display: flex;
    margin-bottom: 20px;
    margin-top: 18px;
  }

  .tab {
    padding: 2px 20px;
    margin-right: 10px;
    background-color: #fcb924;
    border: 1px solid #d1d5d8;
    line-height: 38px;
    border-radius: 4px;
    color: white;
    transition: all 0.2s ease 0s;
    cursor: pointer;
  }

  .tab:hover {
    background-color: rgba(216, 33, 36, 0.8705882353);
  }

  .active {
    background-color: rgba(216, 33, 36, 0.8705882353);
    border-bottom: 1px solid #fcb924; /* чтобы перекрыть рамку снизу */
  }

  .tabContent {
    display: none;
  }

  .activeTabContent {
    display: block;
  }
}
@media (max-width: 760px) {
  .container {
    max-width: 93%;
    margin: 0 auto;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 17px;
    // font-family: "Montserrat", sans-serif;
  }
}
