.scrollToTopButton {
    width: 60px;
    position: fixed;
    bottom: 45px;
    left: 30px;
    // padding: 13px 20px;
    border: none;
    border-radius: 50%;
    // background-color: #007bff;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    z-index: 10;
}

.scrollToTopButton:hover {
    background-color: #0056b3;
}

/* Показываем кнопку, когда пользователь прокручивает вниз на 500px */
body.scrolled .scrollToTopButton {
    display: block;
}
