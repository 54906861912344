a {
  text-decoration: none;
  box-sizing: border-box;
}

.tour {
  width: 100%;
  position: relative;
}
.tour .image {
  width: 100%;
  height: 280px;
  overflow: hidden;
  background-position: center !important;
  background-size: cover !important;
  position: relative;
  border-radius: 5px;
}
.duration {
  font-family: "Montserrat", sans-serif;

  position: absolute;
  z-index: 9;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4px 7px;
  right: 10px;
  top: 10px;
  font-size: 10pt;
}
.price {
  position: absolute;
  z-index: 9;
  background: #e7505b;
  color: white;
  width: 210px;
  text-align: center;
  font-weight: 400;
  font-size: 15pt;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 10px;
  left: 10px;
  border-radius: 5px;
}
.name {
  font-weight: 500;
  font-size: 17px;
  margin-top: 10px;
  color: black;
  text-align: center;
}
