/* Модальное окно */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* полупрозрачный фон */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.modal_f {
    margin-top: 40px;
    background-color: #fff;
    border-radius: 5px;
    padding: 13px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    /* тень */
}

.close-button {
    position: relative;
    left: 177px;
    top: -6px;
    /* right: 10px; */
    background-color: transparent;
    border: none;
    cursor: pointer;
}