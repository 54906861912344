* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

label.error {
  display: none !important;
}

// body {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   min-height: 100vh;
// }

.form_mod {
  border: 1px solid #c3c3c3;
  padding: 15px;
  width: 240px;

  .name,
  #phone {
    width: 100%;
    height: 35px;
    border: 1px solid #c3c3c3;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 0 15px;
    display: flex;
    align-items: center;

    &.error {
      border-color: red;
    }
  }

  textarea {
    width: 100%;
    border: 1px solid #c3c3c3;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 15px;
    display: flex;
    align-items: center;
    line-height: 18px;
  }
  button {
    background-color: transparent;
  }
  .send-form {
    height: 35px;
    width: 100%;
    cursor: pointer;
    color: white;
    background: rgba(216, 33, 36, 0.8705882353);
    border-radius: 5px;
  }
}
