.container {
  max-width: 90%;
  margin: 0 auto;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 40px;

  .searchResultTour {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    padding: 16px;
    background: #fff;
    // -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    // box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);

    .tour_photo {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 150px;
      flex: 0 0 150px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-right: 16px;
      width: 150px;
      height: 150px;
      overflow: hidden;
    }

    .tour_info {
      .title {
        text-align: center;
        display: block;
        margin-bottom: 10px;
        width: 100%;
        padding-right: 40px;
        line-height: 36px;
        font-size: 20px;
        font-weight: 700;
        color: #1196dd;
      }

      .tour_price {
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: 700;
        color: #646464;
      }
      .tour_description {
      }
      .tour_info {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
        flex-flow: column nowrap;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        position: relative;
        color: #9d9d9d;
      }
    }
  }
}

.swiper_slide {
  text-align: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #83ff8b;
  padding-bottom: 13px;
  > div:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: white;
    background-color: rgb(74 74 243); /* ваш цвет текста */
  }
}

@media (max-width: 768px) {
  .container {
    margin: 0 auto;
    max-width: 85%;

    .searchResultTour {
      display: block;

      .tour_photo {
        margin: 0 auto 16px;
        width: 100%;
        max-width: 375px;
        max-height: 280px;
      }

      .tour_info {
        .title {
          font-size: 18px;
          line-height: 24px;
          padding: 0;
        }
        .tour_price {
          font-size: 18px;
        }
      }
    }
  }
}
