.container {
  max-width: 1140px;
  margin: auto;

  .tours {
    margin-top: 16px;
  }
  .title {
    text-align: center;
    margin-top: 18px;
    font-size: 24px;
  }
  .online {
    color: #c6c3c3;
    font-size: 12px;
    text-align: end;
    margin: 4px 29px;
  }
}
